import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import emailjs from "emailjs-com";
import "./Section3.css";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Section3 = () => {
  const { t } = useTranslation(); // Initialize translation hook

  const mapStyles = {
    height: "300px",
    width: "100%",
  };

  // Default center of the map
  const defaultCenter = {
    lat: 48.848,
    lng: 17.225,
  };

  // Locations for the two markers
  const locations = [
    { lat: 48.84481154531559, lng: 17.23261536837177, label: t("map.sidle") },
    { lat: 48.85243962394103, lng: 17.217738477583644, label: t("map.sklad") },
  ];

  const [activeMarker, setActiveMarker] = useState(null);

  // Initialize formData state correctly
  const [formData, setFormData] = useState({
    from_name: "",
    company: "",
    reply_to: "",
    message: "",
  });

  const handleMarkerClick = (index) => {
    setActiveMarker(index); // Set active marker when clicked
  };

  const handleCloseClick = () => {
    setActiveMarker(null); // Close info window
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form data state with the correct input field values
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically set the field based on name attribute
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs
      .sendForm(
        "service_pi6f8dg", // Replace with your EmailJS service ID
        "template_fko8wzh", // Replace with your EmailJS template ID
        e.target,
        "YvZxlvDBXrxWQUo5b" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(t("form.success")); // Use translation for success alert
        },
        (error) => {
          console.log(error.text);
          alert(t("form.error")); // Use translation for error alert
        }
      );

    e.target.reset(); // Reset the form after submission
  };

  return (
    <section className="section3-section">
      <div className="contactbox1-div">
        <div className="contactinfo-div">
          <h2>{t("contact.header")}</h2>
          <p>
            <strong>{t("contact.sidle")}:</strong> 909 01 Skalica Ružová 41
            <br />
            <strong>{t("contact.sklad")}:</strong> 909 01 Skalica Nádražná 33
            <br />
            <strong>{t("contact.ico")}:</strong> 36 686 735
            <br />
            <strong>{t("contact.ic_dph")}:</strong> SK2022254355
            <br />
            <strong>{t("contact.telephone")}:</strong> +421 940 620 089
            <br />
            <strong>{t("contact.email")}:</strong> pptechniksk@gmail.com
            <br />
            <strong>{t("contact.business_email")}:</strong> obchod@pptechnik.sk
            <br />
            <strong>{t("contact.info_email")}:</strong> info@pptechnik.sk
            <br />
            <strong>{t("contact.domain")}:</strong> pptechnik.sk
            <br />
            {t("contact.registration")}
          </p>
        </div>

        <div className="contactform-div">
          <form onSubmit={handleSubmit}>
            <label htmlFor="from_name">{t("form.name")}</label>
            <input
              type="text"
              id="from_name"
              name="from_name" 
              value={formData.from_name} 
              onChange={handleChange} 
              required
            />
            <br />

            <label htmlFor="company">{t("form.company")}</label>
            <input
              type="text"
              id="company"
              name="company" 
              value={formData.company} 
              onChange={handleChange} 
              required
            />
            <br />

            <label htmlFor="reply_to">{t("form.email")}</label>
            <input
              type="email"
              id="reply_to"
              name="reply_to" 
              value={formData.reply_to} 
              onChange={handleChange}
              required
            />
            <br />

            <label htmlFor="message">{t("form.subject")}</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <br />

            <button type="submit">
              {t("form.submit_button")}
            </button>
          </form>
        </div>

        <div className="map-div">
          <LoadScript googleMapsApiKey="AIzaSyCd_dMJ1NMj_s_n_k4yGFSQpAzyAO7royg">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={14}
              center={defaultCenter}
            >
              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => handleMarkerClick(index)}
                >
                  {activeMarker === index && (
                    <InfoWindow
                      position={{ lat: location.lat, lng: location.lng }}
                      onCloseClick={handleCloseClick}
                    >
                      <div className="info-window">
                        <h4>{location.label}</h4>
                        <a
                          href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="navigate-link"
                        >
                          {t("map.navigate")}
                        </a>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </section>
  );
};

export default Section3;
