import React, { useState } from "react";
import "./Navbar.css";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Navbar = ({ onSwitchSection, onGoToSection1, onGoToSection2 }) => {
  const { t, i18n } = useTranslation(); // Use the hook to access translation
  const [language, setLanguage] = useState("SLOVAK");

  // Function to change language
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div>
      <div className="topbox-div" id="Top">
        <div className="contactbox-div">
          <p className="tnumber-p">{t("topbox.phone")}</p>

          <p className="maillink-p">{t("topbox.email")}</p>
          <a
            className="facebooklink-a"
            href="https://www.facebook.com/p/PP-technik-100075569005344/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/facebook.svg`}
              alt="Facebook"
              style={{ width: "14px", height: "14px", marginRight: "5px" }}
            />
            {t("topbox.facebook")}
          </a>
        </div>
        <div className="languagebox-div">
          <p className="language-p">{language}</p>
          <img
            src={`${process.env.PUBLIC_URL}/img/slovak.svg`}
            alt="Slovak"
            onClick={() => {
              changeLanguage("sk");
              setLanguage("SLOVAK");
            }}
            className={language === "SLOVAK" ? "active" : ""}
            style={{ height: "23px", cursor: "pointer" }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/eng.svg`}
            alt="English"
            onClick={() => {
              changeLanguage("en");
              setLanguage("ENGLISH");
            }}
            className={language === "ENGLISH" ? "active" : ""}
            style={{ height: "23px", cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="navbar-div">
        <div className="logobox-div">
          <img className="logoimg-img" src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Logo" />
          <p className="logoname-p">PP-Technik</p>
        </div>
        <div className="navbox-div">
          <a href="#Top" onClick={onGoToSection1} className="nav-a">
            {t("navbar.home")}
          </a>
          <a href="#Section2" onClick={onGoToSection2} className="nav-a">
            {t("navbar.about")}
          </a>
          <a href="#" onClick={onSwitchSection} className="nav-a">
            {t("navbar.contact")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
