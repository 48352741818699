import React, { useState, useEffect } from "react";
import "./Section1.css";
import Container from "./Container.js";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Section1 = ({ handleSectionSwitch }) => {
  const { t } = useTranslation(); // Initialize translation hook

  const containers = [
    <Container
      h1={t("smaterial.title")}
      p={t("smaterial.description")}
      img_src="smat.png"
    >
      <button onClick={handleSectionSwitch} className="button-button">
        {t("smaterial.button")}
      </button>
    </Container>,
    <Container
      h1={t("ktechnika.title")}
      p={t("ktechnika.description")}
      img_src="kotv.png"
    >
      <button onClick={handleSectionSwitch} className="button-button">
        {t("ktechnika.button")}
      </button>
    </Container>,
    <Container
      h1={t("naradie.title")}
      p={t("naradie.description")}
      img_src="pnar.png"
    >
      <button
        onClick={() =>
          window.open("https://katalogprofesionalnehonaradia.sk/", "_blank")
        }
        className="button-button"
      >
        {t("naradie.button")}
      </button>
    </Container>,
    <Container
      h1={t("vdiely.title")}
      p={t("vdiely.description")}
      img_src="vdie.png"
    >
      <button onClick={handleSectionSwitch} className="button-button">
        {t("vdiely.button")}
      </button>
    </Container>,
    <Container
      h1={t("kooperacie.title")}
      p={t("kooperacie.description")}
      img_src="koop.png"
    >
      <button onClick={handleSectionSwitch} className="button-button">
        {t("kooperacie.button")}
      </button>
    </Container>,
    <Container
      h1={t("tpodpora.title")}
      p={t("tpodpora.description")}
      img_src="tpod.png"
    >
      <button onClick={handleSectionSwitch} className="button-button">
        {t("tpodpora.button")}
      </button>
    </Container>,
  ];

  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState(1);
  const [arrowVisible, setArrowVisible] = useState(true); // State to control arrow visibility

  const increment = () => {
    setCurrent((current + 1) % containers.length);
    setDirection(1);
  };

  const decrement = () => {
    setCurrent((current - 1 + containers.length) % containers.length);
    setDirection(-1);
  };

  // Effect to hide arrow when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setArrowVisible(false);
      } else {
        setArrowVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="section1-section" id="Section1">
      <img
        className="arrow1-img"
        src={`${process.env.PUBLIC_URL}/img/arrow1.svg`}
        onClick={decrement}
        alt="Prev"
      />
      <TransitionGroup className="box-div">
        <CSSTransition
          key={current}
          timeout={500}
          classNames={
            direction === 1 ? "container-transition" : "container-transition2"
          }
        >
          {containers[current]}
        </CSSTransition>
      </TransitionGroup>
      <img
        className="arrow2-img"
        src={`${process.env.PUBLIC_URL}/img/arrow2.svg`}
        onClick={increment}
        alt="Next"
      />
      <div className="bottomarrow-div">
        <div className="arrow-container">
          <img
            className="arrow3-img"
            src={`${process.env.PUBLIC_URL}/img/arrowdown.svg`}
            alt="down"
            style={{ opacity: arrowVisible ? 1 : 0 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;
