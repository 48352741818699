import React from "react";
import "./Section2.css";
import { useTranslation } from 'react-i18next';

const Section2 = () => {
  const { t } = useTranslation();

  // Split the text by new line characters and insert <br /> tags
  const splitText = t('about.description').split('\n').map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br /> {/* Add line breaks for each new paragraph */}
    </React.Fragment>
  ));

  return (
    <section className="section2-section" id="Section2">
      <h1 className="header1-h1">{t('about.header')}</h1>
      <p className="text1-p">
        {splitText}
      </p>
    </section>
  );
};

export default Section2;
