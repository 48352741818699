import React from "react";
import "./Footer.css";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Footer = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <footer className="footer-footer">
      <div className="footertext-div">
        <div className="lefttext-div">
          <p >{t("footer.created_by")}&nbsp;</p>
          <a
            href="https://tomash60.github.io/portfolio_website/"
            target="_blank"
            rel="noopener noreferrer"
          >
            me
          </a>
        </div>
        <p>{t("footer.rights_reserved")}</p>
      </div>
    </footer>
  );
};

export default Footer;
