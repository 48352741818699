import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navbar from "./components/header/Navbar";
import "./App.css";
import Section1 from "./components/sections/Section1";
import Section2 from "./components/sections/Section2";
import Section3 from "./components/sections/Section3";
import Footer from "./components/footer/Footer";

function App() {
  const [activeSection, setActiveSection] = useState("sections1and2");

  // Function to switch to Section 3
  const handleSectionSwitch = () => {
    setActiveSection("section3");
  };

  // Function to switch back to Section 1 and Section 2
  const handleGoToSection1 = () => {
    setActiveSection("sections1and2");
  };

  return (
    <div className="App">
      <header className="header">
        <Navbar
          onSwitchSection={handleSectionSwitch}
          onGoToSection1={handleGoToSection1}
          onGoToSection2={handleGoToSection1}
        />
      </header>

      <TransitionGroup className="sections-div">
        <CSSTransition
          key={activeSection} // A unique key to trigger the transition
          timeout={300} // 500ms transition duration
          classNames="fade" // CSS class for transitions
        >
          <div>
            {activeSection === "sections1and2" ? (
              <>
                <Section1 handleSectionSwitch={handleSectionSwitch} />
                <Section2 />
              </>
            ) : (
              <Section3 />
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>

      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
