import React from "react";
import './Container.css';

const Container = ({ h1, p, img_src, children }) => {
  return (
    <div className="container-div">
      <div className="textbox-div">
        <h1 className="title-h1">{h1}</h1>
        <p className="text-p">{p}</p>
        {children}
      </div>
      <div className="imagebox-div">
        <img src={`${process.env.PUBLIC_URL}/img/${img_src}`} alt={h1} />
      </div>
    </div>
  );
};

export default Container;
